import { authorBaseUrl as baseUrl } from "@/constants"
import Link from "next/link"
import { useEffect } from "react"
import brandLogo from "@/public/icons/BLOK_LOGO.svg"
const HeaderNavLogo = props => {
  const {
    data,
    columnClassNames,
    eventHandler: {
      store: { brandLogoImg = "" } = {},
      callbacks: {
        setBrandLogoImg = () => console.log("Event Not Defined"),
        toggleMenu = () => console.log("Event Not Defined"),
      },
    },
  } = props

  useEffect(() => {
    if (data?.dataLayer?.[data?.id] && data?.dataLayer[data?.id]?.image) {
      const brandLogoPath = data?.dataLayer[data?.id]?.image["repo:path"]
      brandLogoPath && setBrandLogoImg(baseUrl + brandLogoPath)
    }
  }, [data])
  useEffect(() => {
    setBrandLogoImg(brandLogo?.src)
  }, [])
  return (
    <>
      <span
        className="gbh-hamber-menu icon-Hamburger"
        tabIndex="0"
        aria-label="Hamburger-menu"
        role="button"
        onClick={toggleMenu}
      ></span>
      <div
        className={`cmp cmp-image ${
          data?.appliedCssClassNames ? data?.appliedCssClassNames : ""
        } ${columnClassNames ? columnClassNames : ""}`}
      >
        <a
          // coming from AEM
          // href={data?.link
          //   ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
          //   .join("")}

          // delta launch
          href={"/"}
          title={data?.alt}
          className="cmp-image--link"
        >
          <section>
            <img src={brandLogo.src} alt={data?.alt} />
          </section>
        </a>
      </div>
    </>
  )
}

export default HeaderNavLogo
