import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import HeaderNavLogo from "@/components/Shared/HeaderNavLogo"
import HeaderNavTabs from "@/components/Shared/HeaderNavTabs"
import UtilityWidgets from "@/components/Shared/UtilityWidgets/v1/UtilityWidgets"
import UtilityWidgetsLoader from "@/components/Shared/UtilityWidgets/v1/UtilityWidgetsLoader"
import {
  selectGenericState,
  openMenu,
  closeMenu,
} from "@/store/features/genericSlice"
import useIsSsr from "@/hooks/useIsSsr"
import { alterClass } from "@/utils"
import { useRouter } from "next/router"
import throttle from "lodash/throttle"

const HeaderNav = props => {
  const dispatch = useDispatch()
  const isSsr = useIsSsr()
  const {
    menu: { isOpen },
  } = useSelector(selectGenericState)

  const { data, eventHandler } = props

  const toggleMenu = () => {
    isOpen ? dispatch(closeMenu()) : dispatch(openMenu())
  }
  const [scrollDir, setScrollDir] = useState("scrolling down")
  const [lastScrollY, setLastScrollY] = useState(0)

  const router = useRouter()
  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      if (!document.body.classList.contains("gbh-of-scroll"))
        setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up")
      lastScrollY = scrollY > 0 ? scrollY : 1
      ticking = false
      setLastScrollY(lastScrollY)
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    const onScrollThrottle = throttle(onScroll, 1000)
    if (router?.pathname !== "/checkout") {
      window.removeEventListener("scroll", onScrollThrottle)
      window.addEventListener("scroll", onScrollThrottle)
    }

    return () => window.removeEventListener("scroll", onScrollThrottle)
  }, [router])

  useEffect(() => {
    if (scrollDir === "scrolling down") {
      alterClass("gbh-header-logo-menu", "nav-up", "nav-down")
      alterClass("sticky-plp-listing", "nav-down", "nav-up")
      alterClass("sticky-pdp-page", "nav-up", "nav-down")
      alterClass("sticky-checkout-page", "nav-up", "nav-down")
    } else if (scrollDir === "scrolling up") {
      alterClass("gbh-header-logo-menu", "nav-up", "nav-down")
      alterClass("sticky-plp-listing", "nav-up", "nav-down")
      alterClass("sticky-pdp-page", "nav-up", "nav-down")
      alterClass("sticky-checkout-page", "nav-up", "nav-down")
    } else return
  }, [scrollDir, lastScrollY])

  useEffect(() => {
    if (lastScrollY < 200) {
      alterClass("gbh-header-logo-menu", null, "nav-down")
      alterClass("gbh-header-logo-menu", null, "nav-up")
    }
  }, [lastScrollY, router])

  return (
    <div className={`${data?.gridClassNames}`}>
      {data[":itemsOrder"].map((key, index) => {
        const item = data[":items"][key]
        if (key.includes("image")) {
          return (
            <HeaderNavLogo
              data={
                item.imageComponentProperties
                  ? item.imageComponentProperties
                  : item
              }
              key={`logo-${index}`}
              columnClassNames={`${data.columnClassNames[key]}`}
              eventHandler={eventHandler}
            />
          )
        }
        if (key === "tabs") {
          return (
            <HeaderNavTabs
              data={item}
              key={`tabs-${index}`}
              columnClassNames={`${data.columnClassNames[key]}`}
              isOpen={isOpen}
              toggleMenu={toggleMenu}
              eventHandler={eventHandler}
            />
          )
        }
        if (key === "utilitywidgets") {
          return (
            <React.Fragment key={`utilitywidgets-${index}`}>
              {!isSsr ? (
                <UtilityWidgets
                  data={item}
                  columnClassNames={`${data.columnClassNames[key]}`}
                  eventHandler={eventHandler}
                />
              ) : (
                <UtilityWidgetsLoader />
              )}
            </React.Fragment>
          )
        }
        return null
      })}
    </div>
  )
}

export default HeaderNav
